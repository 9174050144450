import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../axiosInstance";
import Footer from "../Footer";
import styles from "../login.module.css";
import { useSnackbar } from "notistack";
import CookieConsent from "../../CookieConsent/CookieConsent";

function checkIsInFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isInIframe = checkIsInFrame();
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    email: "",
  });

  const query = new URLSearchParams(location.search);
  const l_userId = query.get("userId");
  const l_siteId = query.get("siteId");

  function handleChange(event) {
    setFormErrors({ email: "" });
    setFormData((old) => {
      return { ...old, [event.target.id]: event.target.value };
    });
    setErrorMessage("");
  }
  function isValidEmail(email) {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  }

  function validateForm(formData) {
    try {
      setFormErrors({ email: "" });

      if (formData.email === "") {
        setFormErrors((old) => {
          return { ...old, email: "Email can not be empty" };
        });
        return false;
      }

      if (!isValidEmail(formData.email)) {
        setFormErrors((old) => {
          return { ...old, email: "Invalid email address" };
        });
        return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  }
  async function onSubmit(event) {
    event.preventDefault();
    if (!validateForm(formData)) {
      return;
    }

    try {
      let data = {
        email: formData.email,
      };

      await axiosInstance.post("api/gen-reset-password-link", data);
      setIsLoading(false);
      enqueueSnackbar("Password reset link sent to your email address!", {
        variant: "info",
        className: "bg-grey-400",
      });
      navigate("/login", { state: { userId: l_userId, siteId: l_siteId } });
    } catch (err) {
      setIsLoading(false);
      setErrorMessage(err.response.data.message);
      enqueueSnackbar(err.response.data.message, { variant: "error" });
    }
  }
  return (
    <>
      <main className={`${styles.forgotPassword}`}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <header
          className={`${styles.headerMobile} ${
            isInIframe ? styles.iframecontainer : ""
          } md:hidden flex justify-between items-center`}
        >
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              window.open("/", "_blank");
            }}
          >
            <img
              className="mr-2 md:mr-5 hover:cursor-pointer"
              src={require("../../../images/rocket-logo.svg")}
              alt="Forgot password"
            />
          </Link>
          {isInIframe ? (
            <Link
              to={{
                pathname: "/login",
                search: `?userId=${l_userId}&siteId=${l_siteId}`,
              }}
            >
              <button className={`${styles.primaryBtn} ${styles.signUpBtn}`}>
                Login
              </button>
            </Link>
          ) : (
            <Link to="/login">
              <button className={`${styles.primaryBtn} ${styles.signUpBtn}`}>
                Login
              </button>
            </Link>
          )}
        </header>

        <section className={`${styles.contentContaienr} flex justify-start`}>
          <div className={`${styles.leftContent} hidden md:inline-block`}>
            <div className={`${styles.desktopHeader}`}>
              <Link to="/" className={`${styles.logoDark} `}>
                <img src={require("../../../images/logo-dark.svg")} alt="" />
              </Link>
            </div>
            <img
              src={require("../../../images/login/bg-tab.svg")}
              className="hidden xl:block"
            />
            <img
              src={require("../../../images/login/bg-tab-small.svg")}
              className="small-img xl:hidden"
            />
          </div>
          <div
            className={`${styles.content} md:flex md:flex-col ${
              isInIframe ? styles.nopadding : ""
            }`}
          >
            <div
              className={`${styles.desktopHeader} hidden md:flex justify-end items-center`}
            >
              <span className={`${styles.dontHaveAccount}`}>
                Have an account?
              </span>
              <Link to="/login">
                <button className={`${styles.primaryBtn} ${styles.signUpBtn}`}>
                  Login
                </button>
              </Link>
            </div>
            <div className={`${styles.formContainer}`}>
              <h1 className={`${styles.heading} mt-0 mb-2`}>
                Forgot password?
              </h1>
              <h4 className={`${styles.subHeading} mb-8`}>
                Enter your email and we’ll send you a link to reset.
              </h4>
              <div>
                <form className={`${styles.loginForm}`} onSubmit={onSubmit}>
                  <div className={`${styles.emailRow} flex flex-col`}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                    />
                    {formErrors.email !== "" && (
                      <label className={`${styles.errorLabels}`}>
                        {formErrors.email}
                      </label>
                    )}
                  </div>

                  <button
                    type="submit"
                    className={`${styles.primaryBtn} ${styles.loginBtn}`}
                  >
                    Send Link
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
      {!isInIframe && <CookieConsent />}
    </>
  );
};

export default ForgotPassword;
