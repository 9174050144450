import ClearIcon from "@mui/icons-material/Clear";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { Buffer } from 'buffer';
// import CategoryDropdown from "../components/ChooseTemplate/CategoryDropdown";
// import TemplateTypeDropdown from "../components/ChooseTemplate/TemplateTypeDropdown";
import CategoryItem from "../components/ChooseTemplate/CategoryItem";
import TemplateTypeItem from "../components/ChooseTemplate/TemplateTypeItem";
import TemplateBody from "../components/ChooseTemplate/TemplateBody";
import { Navbar } from "../components/Layout/Navbar";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import GoogleAnalytics from "../components/GoogleAnalytics";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ChooseTemplatePage = () => {
  const location = useLocation();
  const stateData = location.state;
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [templateData, setTemplateData] = useState([]);
  const [templateDataFiltered, setTemplateDataFiltered] = useState<any[]>([]);
  const [selectedTemplateFilter, setSelectedTemplateFilter] = useState("all");
  const [filterToggle, setFilterToggle] = useState(false);
  const [colorPallets, setColorPallets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["userSelectedFilters"]);
  const encodeData = (data) => Buffer.from(JSON.stringify(data), 'utf8').toString('base64');
  const decodeData = (encodedData) => JSON.parse(Buffer.from(encodedData, 'base64').toString('utf8'));
  interface Filter {
    category: string;
    isChecked: boolean;
  }

  const setSortedCategoryData = (data, action = '') => {
    let tTypes = categoryData.filter(
      (oo) => oo.isChecked === true && oo.type === "main"
    );

    const updateCategoryCount = (pageCondition) => {
      return data.map((cat) => {
        let tmpCount = 0;
        if (templateData.length > 0) {
          templateData.forEach((row) => {
            if (
              row.templateCategories.includes(cat.category) &&
              pageCondition(row.pageCount)
            ) {
              tmpCount++;
            }
          });
          return { ...cat, cnt: tmpCount };
        }
      });
    };
    if (tTypes.length === 1) {
      if (tTypes[0].category === "Single Page") {
        data = updateCategoryCount((pageCount) => pageCount < 2);
      } else if (tTypes[0].category === "Multi Page") {
        data = updateCategoryCount((pageCount) => pageCount > 1);
      }
    } else if (tTypes.length === 2 || tTypes.length === 0) {
      // console.log('tTypes, templateData, data', tTypes, templateData, data);
      if (templateData.length > 0) {
        data = data.map((cat) => {
          const tmpCount = templateData.reduce((count, row) => {
            if (cat.category === 'Blank') {
              return 1;
            }
            else {
              return row.templateCategories.includes(cat.category)
                ? count + 1
                : count;
            }
          }, 0);
          return { ...cat, cnt: tmpCount };
        });
      }
    }

    // Now `data` has been correctly updated, so you can proceed with filtering and sorting.
    const blankCat = data.filter((c) => c.category.toLowerCase() === "blank");
    const nonBlankCat = data
      .filter((c) => c.category.trim().toLowerCase() !== "blank")
      .sort((a, b) =>
        a.category
          .trim()
          .toLowerCase()
          .localeCompare(b.category.trim().toLowerCase())
      );

    let allCategories = [...blankCat, ...nonBlankCat];

    setCategoryData(allCategories);

    if (action === 'reset') {
      setCookie("userSelectedFilters", "", {
        path: "/",
        expires: new Date(0)
      });
    }
    if (action === 'click') {
      const encoded = encodeData(allCategories);
      setCookie("userSelectedFilters", encoded, {
        path: "/",
        maxAge: 60 * 60 // 1 hr
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      let response = await axiosInstance.get(
        `/api/configs/template-site-configs`
      );
      setTemplateData(response.data.data);
      // console.log("all data", response.data.data);

      let _singlePageSitesCount = 0;
      let _multiPageSitesCount = 0;
      let tmpData: any = [];
      response.data.data.forEach((row: any) => {
        row.templateCategories.forEach((cat) => {
          let ii = tmpData.findIndex((oo: any) => oo.category === cat);
          if (ii >= 0) {
            if (cat === 'Blank') {
              // For Blank count should be always be 1
              tmpData[ii].cnt = tmpData[ii].cnt;
            }
            else {
              tmpData[ii].cnt = tmpData[ii].cnt + 1;
            }
          } else {
            tmpData.push({
              type: "cat",
              category: cat,
              cnt: 1,
              isChecked: false,
            });
          }
        });
        if (row.pageCount > 1) {
          _multiPageSitesCount += 1;
        } else {
          _singlePageSitesCount += 1;
        }
      });
      tmpData.push({
        type: "main",
        category: "Single Page",
        cnt: _singlePageSitesCount,
        isChecked: true,
      });
      tmpData.push({
        type: "main",
        category: "Multi Page",
        cnt: _multiPageSitesCount,
        isChecked: true,
      });

      const categoryQuery = getQueryVariable("cat");
      if (categoryQuery) {
        let objIndex = tmpData.findIndex(
          (obj) =>
            encodeURIComponent(obj.category.toLowerCase()) ===
            categoryQuery.toLowerCase()
        );
        if (tmpData[objIndex]) {
          tmpData[objIndex].isChecked = true;
          setFilterToggle(true);
        }
      }
      if (cookies.userSelectedFilters && cookies.userSelectedFilters.length > 0) {
        const decoded = decodeData(cookies.userSelectedFilters);
        setSortedCategoryData(decoded);
      }
      else {
        setSortedCategoryData(tmpData);
      }

      let responseAllPallets = await axiosInstance.get(
        `/api/configs/all-template-color-theme`
      );
      setColorPallets(responseAllPallets.data.data);
      setIsLoading(false);
    }
    setIsLoading(true);

    fetchData();
  }, []);

  useEffect(() => {
    let gg: any[] = [];
    let cats = categoryData.filter(
      (oo) => oo.isChecked === true && oo.type === "cat"
    );
    let typeMains = categoryData.filter(
      (oo) => oo.isChecked === true && oo.type === "main"
    );
    //console.log(cats);
    if (cats.length === 0) {
      gg = [...templateData];
    }
    templateData.forEach((row: any) => {
      let inc = false;
      cats.forEach((cat: any) => {
        if (row.templateCategories.includes(cat.category)) {
          inc = true;
        }
      });
      if (inc) {
        gg.push(row);
      }
    });

    const blankTemplates = gg.filter((t) =>
      t.templateCategories.includes("Blank")
    );
    // Prevent two blank templates
    if (typeMains.length == 2 || typeMains.length == 0) {
      if (blankTemplates.length > 1) {
        blankTemplates.splice(-1, 1);
      }
    }

    // const nonBlankTemplates = gg
    //   .filter((t) => !t.templateCategories.includes("Blank"))
    //   .sort((a, b) => {
    //     if (a.templateName < b.templateName) return -1;

    //     return 0;
    //   });
    const nonBlankTemplates = gg
    .filter((t) => !t.templateCategories.includes("Blank"))
    .sort((a, b) => a.templateName.localeCompare(b.templateName));

    let filteredTemplates = [...blankTemplates, ...nonBlankTemplates];
    let tTypes = categoryData.filter(
      (oo) => oo.isChecked === true && oo.type === "main"
    );
    if (tTypes.length === 1) {
      //   //gg = [...templateData];
      // }
      // else {
      let filteredSinglePageTemplates = [];
      let filteredMultiPageTemplates = [];
      let selectedType;
      filteredTemplates.forEach((row: any) => {
        tTypes.forEach((tType: any) => {
          // filteredTypeTemplates[tType.category].push(row);
          selectedType = tType.category;
          if (tType.category === "Multi Page" && row.pageCount > 1) {
            filteredMultiPageTemplates.push(row);
          }
          if (
            tType.category === "Single Page" &&
            (row.pageCount === 1 || row.pageCount === 0)
          ) {
            filteredSinglePageTemplates.push(row);
          }
        });
      });
      filteredTemplates =
        selectedType === "Multi Page"
          ? filteredMultiPageTemplates
          : filteredSinglePageTemplates;
    }
    setTemplateDataFiltered(filteredTemplates);
    //setTemplateDataFiltered([...blankTemplates, ...nonBlankTemplates]);
    handleSetFilterToggle(categoryData, "cat");
    handleSetFilterToggle(categoryData, "main");
  }, [categoryData]);

  const handleSetFilterToggle = (newArray: any, type: any) => {
    //let isAllPagesTrue = true;
    let tTypes = categoryData.filter(
      (oo) => oo.isChecked === true && oo.type === "main"
    );
    let cTypes = categoryData.filter(
      (oo) => oo.isChecked === true && oo.type === "cat"
    );
    if (tTypes.length == 2 && cTypes.length == 0) {
      setFilterToggle(false);
    } else if (tTypes.length == 0 && cTypes.length == 0) {
      setFilterToggle(false);
    } else {
      setFilterToggle(true);
    }
  };

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    // console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  return (
    <>
      <Helmet>
        <title>RocketPages - Choose Template</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap" as="font" type="font/woff2" />
       <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap" rel="stylesheet" />
       <link rel="icon" href="/favicon.ico" />
       {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Navbar {...stateData} title={"Choose template"} />

        <div className="bg-gray-100 flex-row fixed h-screen w-48 xl:w-64 top-[64px] hidden md:flex">
          <div className="pb-16 overflow-y-auto w-full h-full bg-catelina-blue text-white flex flex-col py-2">
            <div className="px-2">
            <div className="sticky top-0 bg-catelina-blue z-10"> {/* Make Pages section sticky */}
                <span className="px-2 py-2 flex">Page(s):</span>
                {categoryData
                  .filter((obj) => obj.type == "main")
                  .map((row: any) => {
                    return (
                      <TemplateTypeItem
                        key={row.category}
                        item={row}
                        isChecked={row.isChecked}
                        onCheckFilterTypeChanged={(val) => {
                          let newArray: any[] = [...categoryData];
                          let idx = newArray.findIndex(
                            (kk: any) => kk.category === row.category
                          );
                          newArray[idx].isChecked = val;
                          setSortedCategoryData(newArray, 'click');
                          handleSetFilterToggle(newArray, "main");
                          // setFilterToggle(true)
                        }}
                      />
                    );
                  })}
                <span className="border-b border-white mx-2 mt-4 mb-3 flex"></span>
              </div>
              <div className="overflow-y-auto max-h-[calc(100vh-200px)]"> {/* Make Categories scrollable */}
                <span className="px-2 py-2 flex">Categories:</span>
                {categoryData
                  .filter((obj) => obj.type == "cat")
                  .map((row: any) => {
                    return (
                      <CategoryItem
                        key={row.category}
                        item={row}
                        isChecked={row.isChecked}
                        onCheckChanged={(val) => {
                          let newArray: any[] = [...categoryData];
                          let idx = newArray.findIndex(
                            (kk: any) => kk.category === row.category
                          );
                          newArray[idx].isChecked = val;
                          setSortedCategoryData(newArray, 'click');
                          handleSetFilterToggle(newArray, "cat");
                          //removeQueryParam();
                          // setFilterToggle(true)
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full my-4 pl-[20px] md:pl-[260px] xl:pl-[340px] pb-10 pt-[64px] bg-white-smoke pr-[20px]">
          <div className="md:hidden">
            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{
                  p: 0,
                  mx: 1.2,
                  mb: 0,
                  "& .MuiAccordionSummary-content": {
                    alignItems: "center",
                  },
                }}
                style={{ alignItems: "center" }}
              >
                <img
                  src={require("../images/expand.svg")}
                  alt=""
                  className="px-2"
                />
                Filters
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <span className="border-b border-gray flex mx-2 mt-1 mb-1.5"></span>
                <div className="pb-2 text-catelina-blue pt-1">
                  <span className="px-2 py-2" style={{ color: "#3B3B3B66" }}>
                    Page(s):
                  </span>
                  {categoryData
                    .filter((obj) => obj.type == "main")
                    .map((row: any) => {
                      return (
                        <TemplateTypeItem
                          key={row.category}
                          item={row}
                          isChecked={row.isChecked}
                          onCheckFilterTypeChanged={(val) => {
                            let newArray: any[] = [...categoryData];
                            let idx = newArray.findIndex(
                              (kk: any) => kk.category === row.category
                            );
                            newArray[idx].isChecked = val;
                            // console.log(newArray);
                            setSortedCategoryData(newArray, 'click');
                          }}
                        />
                      );
                    })}
                  <span className="border-b border-gray flex mx-2 mt-1 mb-1.5"></span>

                  <span className="px-2 py-2 " style={{ color: "#3B3B3B66" }}>
                    Categories:
                  </span>
                  {categoryData
                    .filter((obj) => obj.type == "cat")
                    .map((row: any) => {
                      return (
                        <CategoryItem
                          key={row.category}
                          item={row}
                          isChecked={row.isChecked}
                          onCheckChanged={(val) => {
                            let newArray: any[] = [...categoryData];
                            let idx = newArray.findIndex(
                              (kk: any) => kk.category === row.category
                            );
                            newArray[idx].isChecked = val;
                            setSortedCategoryData(newArray, 'click');
                          }}
                        />
                      );
                    })}
                  {/* <TemplateTypeDropdown
                    items={categoryData.filter(obj => obj.type == 'main')}
                    handleChange={handleTemplateTypeDropdown}
                  />
                  <CategoryDropdown
                    items={categoryData.filter(obj => obj.type == 'cat')}
                    handleChange={handleCategoriesDropdown}
                  /> */}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          {!filterToggle ? (
            <div className="flex flex-row mb-2">
              <span className="text-catelina-blue py-1">
                All Templates ({templateDataFiltered.length})
              </span>
            </div>
          ) : (
            <div className="template-filter">
              <Accordion disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      alignItems: "center",
                    },
                  }}
                  style={{ alignItems: "center" }}
                >
                  <img
                    src={require("../images/expand.svg")}
                    alt=""
                    className="pr-2"
                  />
                  Filters
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                  <div>
                    {categoryData.filter(
                      (kk: any) => kk.isChecked === true && kk.type === "main"
                    ).length > 0 && (
                      <div className="flex flex-row flex-wrap  pt-2 border-grey-500 border-t">
                        <span className="py-2 font-normal pageLabel">
                          Page(s):
                        </span>
                        <div className="flex flex-wrap pageValue">
                          {categoryData
                            .filter(
                              (kk: any) =>
                                kk.isChecked === true && kk.type === "main"
                            )
                            .map((jjj) => {
                              return (
                                <div
                                  key={jjj.category}
                                  className="border border-slate-300 rounded tex-grey-primary flex items-center pr-1 mr-2 mt-1 mb-2 font-normal grey-primary"
                                  style={{ backgroundColor: "#FAFAFA" }}
                                >
                                  <span className="px-2">{jjj.category}</span>
                                  <ClearIcon
                                    onClick={() => {
                                      let newArray: any[] = [...categoryData];
                                      let idx = newArray.findIndex(
                                        (kk: any) =>
                                          kk.category === jjj.category
                                      );
                                      newArray[idx].isChecked = false;
                                      setSortedCategoryData(newArray, 'reset');
                                    }}
                                    className="hover:cursor-pointer"
                                    fontSize="small"
                                    sx={{ color: "#3B3B3B66" }}
                                  />
                                </div>
                              );
                            })}
                          {/* <span
                          className="text-catelina-blue py-1 pl-1 hover:cursor-pointer self-center underline font-normal"
                          onClick={() => {
                            let newArray: any[] = [...categoryData];
                            for (let iii = 0; iii < newArray.length; iii++) {
                              newArray[iii].isChecked = false;
                            }
                            setSortedCategoryData(newArray);
                          }}
                        >
                          Clear all
                        </span>{" "} */}
                        </div>
                      </div>
                    )}

                    {categoryData.filter(
                      (kk: any) => kk.isChecked === true && kk.type === "cat"
                    ).length > 0 && (
                      <div className="flex flex-row flex-wrap">
                        <span className="py-2 font-normal pageLabel">
                          Categories:
                        </span>
                        <div className="flex flex-wrap pageValue">
                          {categoryData
                            .filter(
                              (kk: any) =>
                                kk.isChecked === true && kk.type === "cat"
                            )
                            .map((jjj) => {
                              return (
                                <div
                                  key={jjj.category}
                                  className="border border-slate-300 rounded flex items-center pr-1 mr-2 mt-1 mb-2 font-normal tex-grey-primary"
                                  style={{ backgroundColor: "#FAFAFA" }}
                                >
                                  <span className="px-2">{jjj.category}</span>
                                  <ClearIcon
                                    onClick={() => {
                                      let newArray: any[] = [...categoryData];
                                      let idx = newArray.findIndex(
                                        (kk: any) =>
                                          kk.category === jjj.category
                                      );
                                      newArray[idx].isChecked = false;
                                      setSortedCategoryData(newArray, 'reset');
                                    }}
                                    className="hover:cursor-pointer"
                                    fontSize="small"
                                    sx={{ color: "#3B3B3B66" }}
                                  />
                                </div>
                              );
                            })}
                          <span
                            className="text-catelina-blue py-1 pl-1 hover:cursor-pointer self-center underline font-normal"
                              onClick={() => {
                              let newArray: any[] = [...categoryData];
                              for (let iii = 0; iii < newArray.length; iii++) {
                                // console.log(newArray[iii]);
                                if (newArray[iii].type === "cat") {
                                  newArray[iii].isChecked = false;
                                }
                              }
                              setSortedCategoryData(newArray, 'reset');
                            }}
                          >
                            Clear all
                          </span>{" "}
                        </div>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <TemplateBody
            templateData={templateDataFiltered}
            colorPallets={colorPallets}
          />
        </div>
      </div>
      <CookieConsent />
    </>
  );
};
