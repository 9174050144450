import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { closeSnackbar, useSnackbar } from "notistack";
import * as React from "react";
import { useCookies } from "react-cookie";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axiosInstance from "../../axiosInstance";
import { Link } from "@mui/material";

const SiteItem = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] =
    React.useState(false);
  const [showRename, setShowRename] = React.useState(false);
  const [renameError, setRenameError] = React.useState("");
  const [newSiteName, setNewSiteName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isPublishing, setIsPublishing] = React.useState(false);
  const [cookie_userData, setCookie_userData, removeCookie_userData] =
    useCookies(["rp_user_data"]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [textClassObj] = React.useState([
    { fontSize: "14px", color: "#243060", minHeight: "auto" },
    {
      "&:hover": {
        fontWeight: 700,
      },
    },
  ]);

  const handleDelete = () => {
    // onCancelSubs(existingSubs[0].subscriptionId); // Call the delete function passed as a prop
    setShowConfirmation(false); // Close the confirmation dialog after delete
    props.onSiteDelete(props.data.siteId);
  };

  const publishSite = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("/api/configs/publish", {
        siteId: props.data.siteId,
        fullSite: true,
        pageId: null,
        publishAllPages: true,
      });
      setIsLoading(false);
      enqueueSnackbar(
        "Your website is being published. We'll notify you when it's ready.",
        {
          variant: "success",
          className: "bg-catelina-blue",
        }
      );
      checkPublishStatus();

      return response;
    } catch (error) {
      console.error("Error while publishing site:", error);
      setIsLoading(false);
    }
  };

  const onPublishViewClick = async () => {
    setShowPublishConfirmation(false);
    if (!props.data.isPublished) {
      setIsPublishing(true);
      const resp = await publishSite();
      // if (!!resp?.data?.data?.url) {
      //   window.open(`https://${resp?.data?.data?.url}`, '_blank')?.focus();
      // } else {
      //   console.log('Error while publishing site');
      // }
    } else {
      window.open(props.data.siteUrl, "_blank")?.focus();
    }
  };

  const getPublishStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/configs/check-publish-status?siteId=${props.data.siteId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error while getting site publish status:", error);
      setIsLoading(false);
    }
  };

  const checkSSL = async (url) => {
    try {
      const response = await axiosInstance.get(
        `/api/configs/check-ssl?url=${url}`
      );
      return response.status;
    } catch (error) {
      console.error("Error while checking SSL:", error);
      setIsLoading(false);
    }
  };

  const checkPublishStatus = function () {
    let lastTimeout;
    let checkPublishStatusInterval = setInterval(async () => {
      const publishResponse = await getPublishStatus();
      if (publishResponse?.data === "Completed") {
        clearInterval(checkPublishStatusInterval);
        if (lastTimeout) {
          clearTimeout(lastTimeout);
        }

        if (!props.data.siteUrl.endsWith(process.env.SERVER_DOMAIN)) {
          let checkInterval = setInterval(async () => {
            const sslResponse = await checkSSL(`${props.data.siteUrl}`);

            if (sslResponse === 200) {
              clearInterval(checkInterval);

              showPublishNotification(props.data.siteUrl);
            } else if (sslResponse === 500) {
              clearInterval(checkInterval);
              showErrorNotification();
            }
          }, 5000);

          setTimeout(() => {
            if (checkInterval) {
              clearInterval(checkInterval);
              showErrorNotification();
            }
          }, 120 * 1000);
        } else {
          showPublishNotification(props.data.siteUrl);
        }
      } else if (publishResponse?.data === "Failed") {
        if (checkPublishStatusInterval) {
          clearInterval(checkPublishStatusInterval);
        }
        if (lastTimeout) {
          clearTimeout(lastTimeout);
        }
        showErrorNotification();
      }
    }, 5000);

    lastTimeout = setTimeout(() => {
      if (checkPublishStatusInterval) {
        clearInterval(checkPublishStatusInterval);
        showErrorNotification();
      }
    }, 60 * 1000);
  };

  const showPublishNotification = (domain = "") => {
    setIsPublishing(false);
    enqueueSnackbar(
      <div
        className="flex flex-col"
        onClick={() => {
          closeSnackbar();
        }}
      >
        <span>Publishing Completed!</span>
        <a className="text-white underline" href={`${domain}`} target="_blank">
          Visit website
        </a>
      </div>,
      {
        variant: "success",
        className: "bg-catelina-blue",
        persist: true,
      }
    );
  };

  const showErrorNotification = (
    msg = "Something went wrong while publishing your site"
  ) => {
    setIsPublishing(false);
    enqueueSnackbar(msg, {
      variant: "error",
    });
  };

  const handleRename = () => {
    // console.log("rename", props.sitesData, newSiteName);
    if (newSiteName === "") {
      setRenameError("Site name can't be blank");
      return;
    } else {
      let idx = props.sitesData.findIndex(
        (kk) =>
          kk.siteName.toLowerCase() === newSiteName.toLowerCase() &&
          kk.siteId !== props.data.siteId
      );
      if (idx >= 0) {
        setRenameError("Entered site name already exists");
        return;
      }
    }

    setShowRename(false);
    props.onSiteRename(props.data.siteId, newSiteName);
  };

  const menuItems = [
    <MenuItem
      key="publish-view"
      onClick={() => {
        if (props.data.isPublished) {
          onPublishViewClick();
        } else {
          if (isPublishing) {
            enqueueSnackbar("Publishing already in progress!", {
              variant: "warning",
            });
            return;
          }
          setShowPublishConfirmation(true);
        }
      }}
      sx={textClassObj}
    >
      <ListItemIcon>
        <VisibilityOutlinedIcon sx={textClassObj} />
      </ListItemIcon>
      {!props.data.isPublished && "Publish & "}View
    </MenuItem>,
    <MenuItem
      key="rename"
      onClick={() => {
        setNewSiteName("");
        setShowRename(true);
      }}
      sx={textClassObj}
      // disabled={props.data.siteId === "2e2b85c5-1b76-4e74-8e39-c82749386c53"}
    >
      <ListItemIcon>
        <DriveFileRenameOutlineOutlinedIcon
          fontSize="small"
          sx={textClassObj}
        />
      </ListItemIcon>
      Rename
    </MenuItem>,
  ];

  if (
    cookie_userData?.rp_user_data?.userId &&
    props.data.siteOwnerUserId === cookie_userData.rp_user_data.userId
  ) {
    menuItems.push(
      <MenuItem
        key="duplicate"
        onClick={() => {
          props.onSiteDuplicate(props.data.siteId);
        }}
        sx={textClassObj}
      >
        <ListItemIcon>
          <ContentCopyRoundedIcon sx={textClassObj} />
        </ListItemIcon>
        Duplicate
      </MenuItem>,
      <MenuItem
        key="delete"
        onClick={() => setShowConfirmation(true)}
        sx={[...textClassObj, { color: "#F00" }]}
        disabled={
          props.data.isTemplate === "true"
          // || props.data.siteId === "2e2b85c5-1b76-4e74-8e39-c82749386c53"
        }
      >
        <ListItemIcon>
          <DeleteOutlineOutlinedIcon
            sx={[...textClassObj, { color: "#F00" }]}
          />
        </ListItemIcon>
        Delete
      </MenuItem>
    );
  }
  return (
    <Card className="relative site-card">
      {showConfirmation && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white rounded p-8 max-w-md w-11/12 md:w-full mx-auto z-50">
            <p className="text-gray-800 mb-4">
              Are you sure you want to delete this website?
            </p>
            <div className="flex justify-end flex-col sm:flex-row">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2 focus:outline-none focus:shadow-outline w-full sm:w-auto mb-2.5 sm:mb-0"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {showPublishConfirmation && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white rounded p-8 max-w-md w-11/12 md:w-full mx-auto z-50">
            <p className="text-gray-800 mb-4">
              This will publish all the pages. Are you sure?
            </p>
            <div className="flex justify-end flex-col sm:flex-row">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2 focus:outline-none focus:shadow-outline w-full sm:w-auto mb-2.5 sm:mb-0"
                onClick={() => setShowPublishConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={onPublishViewClick}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      )}
      {showRename && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white rounded p-2 max-w-md w-11/12 md:w-full mx-auto z-50">
            <div className="flex justify-end">
              <CloseOutlinedIcon
                className="hover:cursor-pointer"
                onClick={() => setShowRename(false)}
              />
            </div>
            <div className="flex pt-2 px-4 sm:px-8 pb-4 flex-col justify-between md:flex-row">
              <TextField
                id="outlined-basic"
                // label={renameError !== "" ? renameError : "New website name"}
                label={"New website name"}
                variant="outlined"
                size="small"
                defaultValue={props.data.siteName}
                onChange={(e) => {
                  setRenameError("");
                  setNewSiteName(e.target.value.trim());
                }}
                onBlur={(e) => {
                  setNewSiteName(e.target.value.trim());
                }}
                // error={renameError !== ""}
                className="w-full mb-4 md:w-64 md:mb-0"
              />

              <div className="self-center">
                {/* <button
                  className="bg-catelina-blue text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline text-xs w-20 mr-3"
                  onClick={handleDelete}
                >
                  Save
                </button> */}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ height: 44, width: 144 }}
                  className="w-20 mr-2 capitalize"
                  onClick={handleRename}
                >
                  Save
                </Button>
              </div>
            </div>
            {renameError !== "" ? (
              <p className={`text-red-500 pl-3`}>{renameError}</p>
            ) : null}
          </div>
        </div>
      )}
      <img
        onClick={() =>
          window.open(
            `${process.env.EDITOR_ENDPOINT}/${props.data.siteId}`,
            "_self"
          )
        }
        className="w-full h-[150px] md:h-[200px] object-cover cursor-pointer"
        src={props.data.siteImageURL}
      />
      <CardContent>
        <Typography gutterBottom component="div" className="text-base-2">
          {props.data.siteName}
          {props.data.isTemplate === "true" ? " (Template)" : ""}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.7rem",
            color: "#243060",
            opacity: props.data.isPublished ? 1 : 0.6,
            overflowWrap: "break-word",
          }}
        >
          {props.data.isPublished ? (
            <Link href={props.data.siteUrl} underline="none" target="_blank">
              {props.data.siteUrl.replace(/^https?:\/\//, "")}
            </Link>
          ) : (
            "Not published"
          )}
        </Typography>
      </CardContent>
      <CardActions>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-row">
            <Button
              size="small"
              variant="outlined"
              sx={{ textTransform: "none", marginRight: "6px" }}
              startIcon={<NoteAltOutlinedIcon />}
              onClick={props.onEditSite}
              // disabled={
              //   props.data.siteId === "2e2b85c5-1b76-4e74-8e39-c82749386c53"
              // }
            >
              Edit site
            </Button>

            <span className="relative setting-icon">
              <img
                onClick={
                  // props.data.siteId === "2e2b85c5-1b76-4e74-8e39-c82749386c53"
                  //   ? null
                  //   :
                  props.onSettingClick
                }
                className="w-[36px] hover:cursor-pointer"
                src={require("../../images/site-setting.svg")}
                alt=""
              />
              <span className="icon-tooltip-text">Site Setting</span>
            </span>
          </div>
          <div className="relative site-menu" onClick={handleClick}>
            <img
              onClick={props.onMoreOptionClick}
              className="w-[36px] hover:cursor-pointer"
              src={require("../../images/extra-option.svg")}
              alt=""
            />
            <span className="icon-tooltip-text">Site Menu</span>
          </div>
        </div>
      </CardActions>

      <Menu
        className="siteMenuOptions"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuItems}
      </Menu>
    </Card>
  );
};

export default SiteItem;
