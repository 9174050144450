import React, { useState, ChangeEvent, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useCookies } from "react-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import axiosInstance from "../axiosInstance";
import { imageData } from "../utils/data";
import ImageCropper from "./ImageUploader/ImageCropper";

type Tab = "upload" | "library";

const ImageUploader = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [data, setData] = useState<any>([]);
  const [subsData, setSubsData] = useState<any>();
  const [selected, setSelected] = useState<any>();
  const [totalSize, setTotalSize] = useState<any>();
  const [currentPageImage, setCurrentPageImage] = useState<any>();

  // const [imageRefs, setImageRefs] = useState<any>([]);
  const [tab, setTab] = useState<Tab>("upload");

  const [cookies] = useCookies(["rp_user_id"]);

  const onDrop = useCallback(async (acceptedFiles) => {
    // Handle the files

    const fileNames = Array.from(acceptedFiles).map((file: any) => file.name);
    const contentTypes = Array.from(acceptedFiles).map(
      (file: any) => file.type
    );
    const fileSizes = Array.from(acceptedFiles).map((file: any) => file.size);

    if (contentTypes.length === 0) {
      // console.log('file types', contentTypes)
      return;
    }

    const response = await axios.get(
      `${
        process.env.API_ENDPOINT === ""
          ? "http://localhost:3000"
          : process.env.API_ENDPOINT
      }/${process.env.IMAGE_UPLOAD_PATH?.replace("/image", "/signed-url")}`,
      {
        params: {
          fileNames: fileNames,
          contentTypes: contentTypes,
          fileSizes: fileSizes,
          siteId: props.siteId,
        },
      }
    );

    const signedUrls = response.data;

    // console.log("signedUrls", signedUrls);
    setData((old) => {
      let _old: any[] = [...old];
      signedUrls.map((rr) => {
        _old.unshift({
          ...rr,
          isUploading: true,
          ref: [],
          refCount: 0,
          created_at: Date.now(),
        });
      });
      setSelected(_old[0]);
      // reCalculate(_old);
      return _old;
    });
    setTab("library");

    // Upload each file to S3 using the signed URLs
    signedUrls.map((signedUrl, index) => {
      let _sizeInMegabytes = signedUrl.size / 1024 / 1024;
      // console.log("Uploading size", _sizeInMegabytes)
      if (_sizeInMegabytes > 50) {
        setData((old) => {
          let _old: any[] = [...old];
          let idx = _old.findIndex((oo) => oo.id === signedUrl.id);
          _old[idx].isUploading = false;
          _old[idx].hasError = true;
          _old[idx].errorTitle = `Upload Size limit!`;
          _old[idx].errorDescription = `Maximum upload limit is 50 MB.`;
          _old[idx].thumbnailUrl = ``;
          return _old;
        });
        return;
      }
      let _totalSize = totalSize + signedUrl.size;
      let _totalSizeLimit = 0;

      if (subsData?.storage?.indexOf("GB") >= 0) {
        let _ttt = subsData.storage.split("GB");
        _totalSizeLimit = _ttt[0] * 1024 * 1024 * 1024;
      } else if (subsData?.storage?.indexOf("MB") >= 0) {
        let _ttt = subsData.storage.split("MB");
        _totalSizeLimit = _ttt[0] * 1024 * 1024;
      }
      if (_totalSizeLimit < _totalSize) {
        setData((old) => {
          let _old: any[] = [...old];
          let idx = _old.findIndex((oo) => oo.id === signedUrl.id);
          _old[idx].isUploading = false;
          _old[idx].hasError = true;
          _old[idx].errorTitle = `Storage alert!`;
          _old[
            idx
          ].errorDescription = `Upload limit reached. Please consider upgrading your plan.`;
          _old[idx].thumbnailUrl = ``;
          return _old;
        });
        console.log(`Uploading`, _totalSize, _totalSizeLimit);
        return;
      }

      axios
        .put(signedUrl.uploadURL, acceptedFiles[index], {
          headers: {
            "Content-Type": acceptedFiles[index].type,
          },
        })
        .then(async (res) => {
          try {
            delete signedUrl["uploadURL"];
            await axiosInstance.post(`/api/assets/add-site-assets`, {
              userId: cookies.rp_user_id,
              thumbnailsize: props.thumbnailsize,
              data: [signedUrl],
            });
          } catch (err) {
            console.log("after upload", err);
          }
          setData((old) => {
            let _old: any[] = [...old];
            let idx = _old.findIndex((oo) => oo.id === signedUrl.id);
            _old[idx].isUploading = false;
            _old[idx].thumbnailUrl = `${_old[idx].imageUrl}_thumbnail`;
            reCalculate(_old);
            return _old;
          });
        })
        .catch((err) => {});
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/svg+xml": [],
      "image/webp": [],
      "image/gif": [],
    },
  });

  const loadGalleryImages = async () => {
    try {
      setIsLoading(true);

      let response = await axiosInstance.get(
        `/api/assets/site-assets?siteId=${props.siteId}&userId=${cookies.rp_user_id}`
      );
      let _data = response.data;

      // let _data = imageData; //|| response.data
      // setSelected(_data.data[4]);
      // setShowEdit(true);

      reCalculate(_data.data);
      setData(_data.data);
      setSubsData({ ..._data.subsDetails });
      let data: any = [];
      _data.data.map((image) => {
        let _imagesRef = [];
        deepScanFindImageRef(
          _data.site.header,
          image.imageUrl,
          "header~header~Site Header",
          _imagesRef
        );
        deepScanFindImageRef(
          _data.site.footer,
          image.imageUrl,
          "footer~footer~Site Footer",
          _imagesRef
        );

        for (let iii = 0; iii < _data.pages.length; iii++) {
          if (_data.pages[iii].pageId !== props.pageId) {
            deepScanFindImageRef(
              _data.pages[iii].configs,
              image.imageUrl,
              `page~${_data.pages[iii].pageId}~${_data.pages[iii].pageName}`,
              _imagesRef
            );
          }
        }
        let jjj = 0;
        _imagesRef.forEach((lklk: any) => (jjj += lklk.count));
        data.push({ ...image, ref: _imagesRef, refCount: jjj });
      });
      // setImageRefs(_imagesRef)
      // console.log("sssss", data);

      setData(data);
      // setIsLoading(false);
    } catch (err) {
      console.error("loadGalleryImages", err);
    } finally {
      setIsLoading(false);
    }
  };
  const reCalculate = (data) => {
    let _sum = 0;
    let _count = 0;
    data.forEach((rr) => {
      _sum += rr.size;
      _count += 1;
    });
    setTotalSize(_sum);
  };

  function deepScanFindImageRef(element, imageUrl, type, _items) {
    if (Array.isArray(element)) {
      // console.log("Array found:", element);
      for (let i = 0; i < element.length; i++) {
        deepScanFindImageRef(element[i], imageUrl, type, _items);
      }
    } else if (typeof element === "object" && element !== null) {
      // console.log("Object found:", element);
      if (element.i && element.i.id === imageUrl) {
        let _ttt = type.split("~");

        let _index = _items.findIndex(
          (kk) =>
            kk.imageUrl === imageUrl &&
            kk.type === _ttt[0] &&
            kk.name === _ttt[2]
        );
        if (_index !== -1) {
          _items[_index].count += 1;
        } else {
          _items.push({
            imageUrl: imageUrl,
            type: _ttt[0],
            id: _ttt[1],
            name: _ttt[2],
            count: 1,
          });
        }

        // element.i.id = newImageUrl;
      }

      for (let key in element) {
        if (element.hasOwnProperty(key)) {
          deepScanFindImageRef(element[key], imageUrl, type, _items);
        }
      }
    } else {
      // console.log("Primitive value found:", element);
    }
  }

  useEffect(() => {
    inIframe();
    loadGalleryImages();
  }, []);

  useEffect(() => {
    if (selected) {
      // Function to handle the message event
      const handleMessage = (event) => {
        if (event.data.type === "imageCount") {
          if (
            event.data.count > 0 &&
            event.data.count !== undefined &&
            event.data.count !== ""
          ) {
            setCurrentPageImage({
              id: event.data.id,
              ref: "Current page",
              refCount: event.data.count,
            });
          } else {
            setCurrentPageImage({ id: "" }); // Reset if no valid count
          }
        }
      };

      // Post the message to the parent window
      parent.postMessage(
        {
          msgType: "uploader:getCount",
          imgUrl: selected.imageUrl,
          id: selected.id,
        },
        "*"
      );

      // Add the event listener
      window.addEventListener("message", handleMessage);

      // Cleanup function to remove the event listener
      return () => {
        window.removeEventListener("message", handleMessage);
      };
    }
  }, [selected]);

  const formatSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} Bytes`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
  };

  const handleImageDelete = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    try {
      console.log("delete", selected);
      let data = { id: selected.id };
      if (selected.key) {
        data["key"] = selected.key;
      } else {
        data["key"] = `${selected.id}-${selected.imageName}`;
      }
      data["userId"] = cookies.rp_user_id;
      let response = await axiosInstance.post(
        `/api/assets/delete-site-asset`,
        data
      );
      // console.log("res", response);
      if (response.status === 200) {
        setData((old) => {
          let _old = [...old.filter((oo) => oo.id !== selected.id)];
          reCalculate(_old);
          return _old;
        });
        setSelected(null);
        // loadGalleryImages();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const [isIniFrame, setIsInIFrame] = useState<any>();
  function inIframe() {
    try {
      setIsInIFrame(window.self !== window.top);
      // return window.self !== window.top;
    } catch (e) {
      setIsInIFrame(true);
      // return true;
    }
  }

  const onClose = () => {
    // console.log("onClose", isIniFrame);
    if (isIniFrame && !props.isBlog) {
      parent.postMessage({ msgType: "uploader:close" }, "*");
      return;
    } else {
      props.onClose();
    }
  };
  const onSelect = () => {
    // console.log("onSelect", isIniFrame);
    var image_upload_popup = checkIfButtonIsInIframe();
    if (isIniFrame && !props.isBlog && image_upload_popup === true) {
      parent.postMessage(
        {
          msgType: "uploader:select",
          imgUrl: selected.imageUrl,
          siteId: props.siteId,
          eid: props.eid,
        },
        "*"
      );
      return;
    } else {
      props.onChange(selected.imageUrl);
    }
  };

  function checkIfButtonIsInIframe() {
    return window.self.location.href.includes("image-uploder");
  }
  const showHistory = () => {
    return (
      <span>
        {selected.ref &&
          selected.ref.map((rr, idx) => {
            return (
              <div
                key={rr.id}
                className="flex flex-row justify-between leading-relaxed "
              >
                <span>{rr.name}</span>
                <span className="pr-2">{`(${rr.count})`}</span>
              </div>
            );
          })}
        {currentPageImage && currentPageImage.id !== "" && (
          <div
            key={currentPageImage.id}
            className="flex flex-row justify-between leading-relaxed"
          >
            <span>Current Page</span>
            <span className="pr-2">{`(${currentPageImage.refCount})`}</span>
          </div>
        )}
      </span>
    );
  };
  return (
    <div className="h-full flex flex-col justify-between bg-white shadow-lg px-2 pt-2 pb-6 font-body image-upload">
      <Backdrop
        sx={{
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {showConfirmation && (
        <div className="fixed inset-0 overflow-y-auto flex justify-center items-center z-10">
          <div className="fixed inset-0">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white rounded p-8 max-w-md w-11/12 md:w-full mx-auto z-50">
            {((selected.ref && selected.ref.length > 0) ||
              (currentPageImage && currentPageImage.id !== "")) && (
              <div className="text-gray-800 mb-4 text-sm">
                <p className="mb-2">
                  <strong>Images used on the following pages: </strong>
                </p>
                {showHistory()}
              </div>
            )}
            <p className="text-gray-800 mb-4">
              Are you sure you want to delete this image?
            </p>
            <div className="flex justify-end flex-col sm:flex-row">
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2 focus:outline-none focus:shadow-outline w-full sm:w-auto mb-2.5 sm:mb-0"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleImageDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {selected && showEdit && (
        <div className="flex flex-col flex-1 justify-center h-full">
          <div className="text-sm font-medium text-center text-gray-500  border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="flex flex-wrap -mb-px md:mt-0 mt-4">
              <span
                className={`hover:cursor-pointer text-catelina-blue text-sm`}
                onClick={() => setShowEdit(false)}
              >
                <ArrowBackIcon sx={{ fontSize: 18 }} />
                Back
              </span>

              <div className="flex flex-row justify-between flex-1 items-center relative">
                <span className="absolute flex flex-row justify-between top-0 right-2">
                  <IconButton size="small" aria-label="close" onClick={onClose}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </div>
            </div>
          </div>
          <ImageCropper
            imageSrc={selected.imageUrl}
            imageName={selected.imageName}
            onClose={() => {
              setShowEdit(false);
            }}
            onSave={(img: any) => {
              setShowEdit(false);
              const file = new File(
                [img],
                `${selected.imageName} - ${Date.now()}`,
                { type: img.type }
              );

              onDrop([file]);
            }}
          />
        </div>
      )}
      {!showEdit && (
        <div className={`flex flex-col mb-2`}>
          <div className="text-sm font-medium text-center text-gray-500  border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="flex flex-wrap -mb-px">
              <span
                className={`inline-block px-4 py-1 ${
                  tab === "upload"
                    ? "text-catelina-blue border-b-2 border-catelina-blue active rounded-t-lg  dark:text-catelina-blue dark:border-catelina-blue"
                    : "border-b-2 border-gray-300 rounded-t-lg hover:text-gray-600 hover:border-gray-400 dark:hover:text-gray-300"
                }  hover:cursor-pointer`}
                onClick={() => setTab("upload")}
              >
                Upload files
              </span>
              <span
                className={`inline-block px-4 py-1 ${
                  tab === "library"
                    ? "text-catelina-blue border-b-2 border-catelina-blue active rounded-t-lg  dark:text-catelina-blue dark:border-catelina-blue"
                    : "border-b-2 border-gray-300 rounded-t-lg hover:text-gray-600 hover:border-gray-400 dark:hover:text-gray-300"
                } hover:cursor-pointer`}
                onClick={() => setTab("library")}
              >
                Media library
              </span>
              <div className="flex flex-row justify-between flex-1 items-center relative">
                <span className="absolute flex flex-row justify-between top-2 right-2">
                  {!isLoading && (
                    <span className="px-4 text-xxsm font-semibold text-catelina-blue hidden sm:block">
                      {formatSize(totalSize)} /{" "}
                      {subsData?.storage
                        ?.replace("GB", " GB")
                        ?.replace("MB", " MB")}
                      {props.thumbnailsize
                        ? `Thumbnail size: ${props.thumbnailsize} px`
                        : ""}
                    </span>
                  )}
                  <IconButton size="small" aria-label="close" onClick={onClose}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {!showEdit && (
        <div className="flex flex-col flex-1 h-[calc(100%-32px)] image-library">
          {tab === "library" && (
            <>
              {data && data.length > 0 && (
                <div className="flex flex-row justify-center relative md:h-full h-[calc(100%-40px)]">
                  <div className="w-full">
                    <div className="w-full overflow-auto h-full ">
                      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 mb-4 p-2">
                        {data &&
                          data.map((_: any, index) => (
                            <div
                              key={_.id}
                              className={`w-full relative max-h-36 lg:max-h-24 bg-gray-200 hover:cursor-pointer border ${
                                selected && _.id === selected.id
                                  ? "ring-offset-2 ring-4 ring-catelina-blue"
                                  : ""
                              } `}
                              onClick={() => {
                                // console.log("selected", _);
                                setSelected(_);
                              }}
                            >
                              {/* <span className="absolute top-0 right-0 -mt-2 -mr-2 h-5 w-5 bg-catelina-blue text-white rounded-full flex items-center justify-center text-xsm">
                            {Math.round(Math.random() * 20)}
                          </span> */}
                              {_.isUploading && _.isUploading === true ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    padding: "16px",

                                    justifyContent: "center",
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              ) : (
                                // <img
                                //   src={_.imageUrl}
                                //   alt={_.imageName}
                                //   className="inset-0 w-full h-full object-cover"
                                // />
                                <LazyLoadImage
                                  src={_.thumbnailUrl || _.imageUrl}
                                  alt={_.imageName}
                                  // effect="blur"
                                  wrapperProps={{
                                    // If you need to, you can tweak the effect transition using the wrapper style.
                                    style: { transitionDelay: "1s" },
                                  }}
                                  className="inset-0 w-full h-full object-cover"
                                />
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="flex md:hidden flex-col mx-2">
                      <Button
                        className="w-full my-2"
                        component="label"
                        size="small"
                        disabled={
                          !selected || selected.isUploading || selected.hasError
                        }
                        role={undefined}
                        variant="contained"
                        onClick={onSelect}
                        tabIndex={-1}
                        sx={{
                          textTransform: "none",
                          paddingRight: 4,
                          paddingLeft: 4,
                        }}
                      >
                        Use media
                      </Button>
                    </div>
                  </div>

                  {selected && (
                    <div className="flex flex-col justify-between">
                      <div
                        className={`w-48 h-[90%] md:h-[100%] md:mx-2 bg-[#FAFAFA] px-2 border border-light-purple rounded-lg border-dashed absolute inset-y-0 left-0 top-0 md:static md:w-64 transform transition-transform duration-300 ease-in-out text-base text-catelina-blue flex flex-col overflow-auto md:shadow-none shadow-lg shadow-gray-300`}
                        // className={`w-48 md:mx-2 bg-[#FAFAFA] px-2 border-2 rounded-lg border-dotted absolute inset-y-0 left-0 top-0 md:static md:w-64 transform transition-transform duration-300 ease-in-out text-base text-catelina-blue flex flex-col overflow-auto`}
                      >
                        <span className="absolute flex flex-row justify-between top-0 right-2 md:hidden">
                          <IconButton
                            size="small"
                            aria-label="close"
                            onClick={() => setSelected()}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        </span>
                        <span className="font-bold text-xsm text-catelina-blue">
                          Media details
                        </span>
                        {selected.hasError && (
                          <div
                            className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
                            role="alert"
                          >
                            <svg
                              className="flex-shrink-0 inline w-4 h-4 me-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <span className="sr-only">Info</span>
                            <div>
                              <span className="font-medium">
                                {selected.errorTitle}{" "}
                              </span>
                              {selected.errorDescription}
                            </div>
                          </div>
                        )}
                        <div className="border-2 max-h-48 w-full bg-grey-300 flex justify-center blank-image-bg">
                          {selected.isUploading &&
                          selected.isUploading === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                padding: "16px",

                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <LazyLoadImage
                              src={selected.thumbnailUrl || selected.imageUrl}
                              alt={selected.imageName}
                              className="inset-0  object-cover max-h-40"
                              // effect="blur"
                              wrapperProps={{
                                // If you need to, you can tweak the effect transition using the wrapper style.
                                style: { transitionDelay: "1s" },
                              }}
                            />
                          )}
                        </div>

                        <span className="text-xsm leading-normal md:text-sm pt-2 w-full">
                          <p className="break-words mb-2">
                            {selected.imageName}
                          </p>
                        </span>

                        <span className="text-xxsm leading-normal md:text-xsm">
                          {formatSize(selected.size)}
                          {/* {parseInt((selected.size / 1024).toString())} KB */}
                        </span>
                        <span className="text-xxsm leading-normal md:text-xsm flex flex-row justify-between">
                          <span>
                            {moment(selected.created_at).format(
                              "MMMM, DD YYYY"
                            )}
                          </span>
                          <span>|</span>
                          <span>
                            {moment(selected.created_at).format("HH:mm")}
                          </span>
                        </span>
                        {!selected.hasError && (
                          <div className="md:mx-4 flex flex-col">
                            <Button
                              className="py-2 mt-6"
                              component="label"
                              size="small"
                              variant="contained"
                              tabIndex={-1}
                              disabled={selected.isUploading}
                              sx={{
                                textTransform: "none",
                                fontSize: 14,
                                paddingRight: 4,
                                paddingLeft: 4,
                              }}
                              startIcon={<EditNoteOutlinedIcon />}
                              onClick={() => {
                                setShowEdit(true);
                                // console.log(selected);
                              }}
                            >
                              Edit image
                            </Button>

                            <Button
                              className="py-2 mt-2"
                              component="label"
                              size="small"
                              color="error"
                              variant="outlined"
                              disabled={selected.isUploading}
                              tabIndex={-1}
                              sx={{
                                textTransform: "none",
                                fontSize: 14,
                                paddingRight: 4,
                                paddingLeft: 4,
                              }}
                              startIcon={<DeleteIcon />}
                              onClick={() => setShowConfirmation(true)}
                            >
                              Delete image
                            </Button>
                          </div>
                        )}
                        <br />
                        <span className="text-xxsm leading-normal md:text-xsm mb-4 mt-2">
                          {selected.refCount ? (
                            <span className="text-catelina-blue font-bold leading-normal ">
                              Image history {"("}
                              {selected.refCount}
                              {")"}{" "}
                            </span>
                          ) : null}
                          {showHistory()}
                        </span>
                      </div>
                      <div className="md:flex hidden flex-col mx-4">
                        <Button
                          className="w-full my-2"
                          component="label"
                          size="small"
                          disabled={
                            !selected ||
                            selected.isUploading ||
                            selected.hasError
                          }
                          role={undefined}
                          variant="contained"
                          onClick={onSelect}
                          tabIndex={-1}
                          sx={{
                            textTransform: "none",
                            paddingRight: 4,
                            paddingLeft: 4,
                          }}
                        >
                          Use media
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!data ||
                (data.length === 0 && (
                  <div className="flex justify-center h-full items-center border rounded-lg">
                    <ImageNotSupportedIcon fontSize="large" />
                  </div>
                ))}
            </>
          )}

          {tab === "upload" && (
            <div className="flex flex-row justify-center h-full">
              <div
                {...getRootProps()}
                className={`flex items-center justify-center w-full h-full border-2 border-dotted border-gray-300 rounded-lg p-4 ${
                  isDragActive ? "bg-gray-100" : "bg-[#FAFAFA]"
                }`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="text-gray-500">Drop the files here ...</p>
                ) : (
                  <div className="flex flex-col items-center  justify-center text-lg text-catelina-blue">
                    <span className="font-bold">Drag & Drop files</span>
                    <span className="font-bold">Or</span>
                    {/* <span className="font-bold">Click anywhere to upload</span> */}
                    <Button
                      className="py-2 mt-2 mb-4"
                      component="label"
                      size="small"
                      variant="contained"
                      tabIndex={-1}
                      sx={{
                        textTransform: "none",
                        fontSize: 14,
                        paddingRight: 4,
                        paddingLeft: 4,
                      }}
                    >
                      Choose a local file
                    </Button>
                    <span className="text-xsm">
                      Maximum upload file size: 50mb
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
