import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import shortUUID from "short-uuid";
import axiosInstance from "../axiosInstance";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import { Navbar } from "../components/Layout/Navbar";
import CategoryDropdown from "../components/SiteSettings/CategoryDropdown";
import ConfigAdministration from "../components/SiteSettings/ConfigAdministration";
import ConfigBillings from "../components/SiteSettings/ConfigBillings";
import ConfigContactForm from "../components/SiteSettings/ConfigContactForm";
import ConfigContributors from "../components/SiteSettings/ConfigContributors";
import ConfigCustomCode from "../components/SiteSettings/ConfigCustomCode";
import ConfigDomain from "../components/SiteSettings/ConfigDomain";
import ConfigGeneral from "../components/SiteSettings/ConfigGeneral";
import ConfigPricingPlan from "../components/SiteSettings/ConfigPricingPlan";
import ConfigSEO from "../components/SiteSettings/ConfigSEO";
import ConfigSubscriptions from "../components/SiteSettings/ConfigSubscriptions";
import ConfigWidgets from "../components/SiteSettings/ConfigWidgets";
import GoogleAnalytics from "../components/GoogleAnalytics";

export const SiteSettingsPage = (props) => {
  const [cookies] = useCookies(["rp_user_data"]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveDisabled, setSaveDisabled] = useState();

  const updateQueryParam = (key, value) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    setSearchParams(params);
  };
  const getQueryParam = (key) => {
    return searchParams.get(key);
  };
  const handleUpdateQueryParam = (section) => {
    updateQueryParam("section", section);
  };
  const colorPalletKeys = shortUUID();
  const { id } = useParams();
  const location = useLocation();
  const [selectedDomainTab, setSelectedDomainTab] = useState("subdomain");
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingSSL, setIsCheckingSSL] = useState(false);
  const [siteData, setSiteData] = useState<any>({});
  const [allTemplateColors, setAllTemplateColors] = useState<any>([]);
  const [formData, setFormData] = useState({
    siteId: id,
    siteName: "",
    faviconImage: "",
    defaultSocialMediaImage: "",
    defaultSocialMediaDescription: "",
    defaultSocialMediaImageAlt: "",
    subDomain: "",
    head_customHtmlCode: "",
    body_customHtmlCode: "",
    primaryLanguage: "en",
    redirects: [],
    subDomainErrorMessage: "",
    cookies: {
      cookieBannerVisible: false,
      cookieText: "",
      cookieButtonText: "",
      cookieBackgroundColor: { r: "24", g: "160", b: "251", a: "100" },
      cookieFontColor: {
        r: "0",
        g: "0",
        b: "0",
        a: "100",
      },
    },
    siteOwnerUserId: "",
    addOnUsers: [],
    currButtonConfig: [],
    isPublished: false,
    templateColorPalettes: [],
    templateCategories: [],
    templateName: "",
    isTemplate: "false",
    siteUrl: "",
  });
  const stateData = location.state;
  const [templateSiteCategories, setTemplateSiteCategories] = useState([]);
  const [siteCategory, setSiteCategory] = useState([
    "General",
    "Domain",
    "Custom Code",
    "SEO",
    // "Widgets",
    // "Contributors",
    "Contact Form",
    // "Billing",
    // "Plans",
    // "Subscriptions",
    // "Administration",
  ]);

  const onFormDataChange = (data) => {
    // console.log("onFormDataChange", data);
    setFormData((old) => {
      return { ...old, ...data };
    });
  };

  const objectWithoutKey = (object, key) => {
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  };

  const deepCompare = (arg1, arg2) => {
    if (
      Object.prototype.toString.call(arg1) ===
      Object.prototype.toString.call(arg2)
    ) {
      if (
        Object.prototype.toString.call(arg1) === "[object Object]" ||
        Object.prototype.toString.call(arg1) === "[object Array]"
      ) {
        if (Object.keys(arg1).length !== Object.keys(arg2).length) return false;

        return Object.keys(arg1).every(function (key) {
          return deepCompare(arg1[key], arg2[key]);
        });
      }

      return arg1 === arg2;
    }

    return false;
  };

  function findDuplicates<T>(array: T[]): T[] {
    const seen: { [key: string]: number } = {};
    const duplicates: T[] = [];

    array.forEach((item) => {
      const itemString = JSON.stringify(item);
      if (seen[itemString]) {
        if (seen[itemString] === 1) {
          duplicates.push(item);
        }
        seen[itemString]++;
      } else {
        seen[itemString] = 1;
      }
    });

    return duplicates;
  }

  const onSave = async () => {
    //validation for templates
    if (formData.templateColorPalettes) {
      let _dup = findDuplicates(
        formData.templateColorPalettes?.map((ol: any) => ol.id)
      );
      let _emptyImage = formData.templateColorPalettes.filter(
        (kl: any) => kl.imageUrl.length === 0
      );

      if (_dup.length > 0 || _emptyImage.length > 0) {
        enqueueSnackbar("Invalid applicable color pallet", {
          variant: "error",
        });
        return;
      }
    }

    if (
      formData.isTemplate === "true" &&
      formData.templateCategories.length === 0
    ) {
      enqueueSnackbar("Atleast one template category should be selected!", {
        variant: "error",
      });
      return;
    }
    if (
      formData.isTemplate === "true" &&
      formData.templateColorPalettes.length === 0
    ) {
      enqueueSnackbar("Atleast one template color pallet should be there!", {
        variant: "error",
      });
      return;
    }
    if (
      formData.isTemplate === "true" &&
      (formData.templateName === "" ||
        !formData.templateName ||
        formData.templateName === null ||
        formData.templateName === undefined)
    ) {
      enqueueSnackbar("Template name can't be blank!", {
        variant: "error",
      });
      return;
    }
    if (
      formData.defaultSocialMediaDescription &&
      formData.defaultSocialMediaDescription.trim().length > 160
    ) {
      enqueueSnackbar("Description exceeds the 160-character limit.", {
        variant: "error",
      });
      return;
    }
    // return;
    setIsLoading(true);
    try {
      if (
        formData.subDomainErrorMessage &&
        formData.subDomainErrorMessage !== ""
      ) {
        enqueueSnackbar(formData.subDomainErrorMessage, { variant: "error" });
        setIsLoading(false);
        return;
      }
      let data = objectWithoutKey(formData, "subDomainErrorMessage");
      data = objectWithoutKey(data, "siteOwnerUserId");
      // console.log("onsave click", data);

      await axiosInstance.post("/api/configs/append-site-config", data);

      if (siteData.isPublished) {
        if (data.subDomain !== siteData.subDomain) {
          await axiosInstance.patch("/api/configs/subdomain", {
            siteId: data.siteId,
          });
        }

        if (
          data.siteName !== siteData.siteName ||
          data.head_customHtmlCode !== siteData.head_customHtmlCode ||
          data.body_customHtmlCode !== siteData.body_customHtmlCode ||
          data.primaryLanguage !== siteData.primaryLanguage ||
          (data.defaultSocialMediaImage && !siteData.defaultSocialMediaImage) ||
          (!data.defaultSocialMediaImage && siteData.defaultSocialMediaImage) ||
          data.defaultSocialMediaDescription !== siteData.defaultSocialMediaDescription ||
          data.defaultSocialMediaImageAlt !== siteData.defaultSocialMediaImageAlt
        ) {
          await axiosInstance.post("/api/configs/publish", {
            siteId: data.siteId,
            fullSite: true,
          });

          checkPublishStatus("Site setting saved successfully!");
        } else {
          if (
            (data.faviconImage &&
              data.faviconImage !== siteData.faviconImage) ||
            data.defaultSocialMediaImage !== siteData.defaultSocialMediaImage
          ) {
            await axiosInstance.patch("/api/configs/site-images", {
              siteId: data.siteId,
              faviconChanged: data.faviconImage !== siteData.faviconImage,
              socialImageChanged:
                data.defaultSocialMediaImage !==
                siteData.defaultSocialMediaImage,
            });
          }

          if (!deepCompare(data.redirects, siteData.redirects)) {
            await axiosInstance.patch("/api/configs/url-redirects", {
              siteId: data.siteId,
            });
          }

          setIsLoading(false);
          enqueueSnackbar("Site setting saved successfully!", {
            variant: "success",
            className: "bg-catelina-blue",
          });
        }
      } else {
        setIsLoading(false);
        enqueueSnackbar("Site setting saved successfully!", {
          variant: "success",
          className: "bg-catelina-blue",
        });
      }

      setSiteData(data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      let message = err.response ? err.response.data.message : err.message;
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  const checkPublishStatus = (successMessage: string) => {
    let checkPublishStatus: any = setInterval(async () => {
      const publishResponse = await axiosInstance.get(
        `/api/configs/check-publish-status?siteId=${siteData.siteId}`
      );
      if (publishResponse.data.data === "Completed") {
        clearInterval(checkPublishStatus);
        checkPublishStatus = false;
        setIsLoading(false);
        enqueueSnackbar(successMessage, {
          variant: "success",
          className: "bg-catelina-blue",
        });
      } else if (publishResponse.data.data === "Failed") {
        clearInterval(checkPublishStatus);
        checkPublishStatus = false;
        setIsLoading(false);
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
      }
    }, 5000);

    setTimeout(() => {
      if (checkPublishStatus) {
        clearInterval(checkPublishStatus);
        checkPublishStatus = false;
        setIsLoading(false);
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
      }
    }, 120 * 1000);
  };

  const onAddOnUsersAdd = async (emails) => {
    setIsLoading(true);
    try {
      let l_data = { siteId: formData.siteId, contributorsEmail: emails };
      // console.log("onAddOnUsersAdd", l_data);
      let rs = await axiosInstance.put(
        "/api/configs/site-config-add-contributors",
        l_data
      );
      // console.log(l_data);

      // Lets send the email.
      emails.map(async (email) => {
        let mailBody = "Dear " + email + ",";
        let siteLink = "https://editor.rocketpages.io/" + l_data.siteId;
        // mailBody += "<br> You are added as contributor";
        mailBody +=
          "<br><br> We are excited to inform you that you have been added as a contributor to RocketPages.";
        mailBody +=
          "<br><br> You can now access the site and start collaborating by visiting " +
          siteLink +
          ".";
        mailBody += "<br><br> Best regards,";
        mailBody += "<br> The RocketPages Team";

        let mailData = {
          to: email,
          subject: "Welcome to RocketPages!",
          text: mailBody,
          html: mailBody,
        };

        let res = await axiosInstance.post("/api/sg-mail/send", mailData);
        //console.log("main_response", res);
      });

      // Lets notify the user that mail has been sent.
      enqueueSnackbar("Contributor mail has been sent!", {
        variant: "info",
        className: "bg-grey-400",
      });

      setIsLoading(false);
      // console.log("save res", rs);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const onAddOnUsersRemove = async (email) => {
    setIsLoading(true);
    try {
      let l_data = { siteId: formData.siteId, email: email };

      // console.log("onAddOnUsersRemove", l_data);
      let rs = await axiosInstance.put(
        "/api/configs/site-config-remove-contributor",
        l_data
      );
      setIsLoading(false);
      // console.log("save res", rs);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const [selectedCategory, setSelectedCategory] = useState("");
  React.useEffect(() => {
    async function fetchSiteData(siteId) {
      setIsLoading(true);
      try {
        let response = await axiosInstance.get(
          `/api/configs/site-config?siteId=${siteId}`
        );

        // console.log("//", response.data);
        setSiteData(response.data);
        setFormData({
          ...response.data,
          templateColorPalettes: response.data.templateColorPalettes
            ? response.data.templateColorPalettes.map((item, index) => ({
                ...item,
                keyIndex: colorPalletKeys.new(),
              }))
            : [],
        });
        if (response.data.siteOwnerUserId === cookies.rp_user_data.userId) {
          setSiteCategory([...siteCategory, "Contributors", "Subscriptions"]);
        }
        let responseAllTemplateColor = await axiosInstance.get(
          `/api/configs/all-template-color-theme`
        );
        setAllTemplateColors(responseAllTemplateColor.data.data);

        let responseTemplateSiteCategories = await axiosInstance.get(
          `/api/configs/all-site-category`
        );
        // console.log(
        //   "responseTemplateSiteCategories",
        //   responseTemplateSiteCategories.data.data
        // );
        setTemplateSiteCategories(responseTemplateSiteCategories.data.data);
        setSiteCategory((_prevData) => {
          if (cookies.rp_user_data.userType === "admin") {
            return [..._prevData, "Administration"];
          } else {
            return [..._prevData];
          }
        });
      } catch (err) {
        if (err?.response?.status === 404) {
          navigate(`/404?msgType=SiteNotFound`);
        } else if (err?.response?.status === 401) {
          navigate(`/404?msgType=SiteNotAuthorizedToAccess`);
        } else {
          console.log("err", err);
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchSiteData(id);
    let gg = getQueryParam("section");
    if (!gg) {
      setSelectedCategory("General");
      // handleUpdateQueryParam(selectedCategory);
    } else {
      setSelectedCategory(gg);
    }
    // console.log('asasas', gg)
    // setSelectedCategory(gg ? "General" : "");
  }, []);

  React.useEffect(() => {
    handleUpdateQueryParam(selectedCategory);
    // console.log("s", selectedCategory);
  }, [selectedCategory]);

  // To hide sitename of preview on mobile view
  const useMobileView = () => {
    const [isMobileView, setIsMobile] = useState(window.innerWidth <= 601);

    React.useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 601px)");
      const handleMediaChange = () => setIsMobile(mediaQuery.matches);

      handleMediaChange(); // Initial check
      mediaQuery.addEventListener("change", handleMediaChange); // Listen for changes

      return () => mediaQuery.removeEventListener("change", handleMediaChange); // Cleanup
    }, []);

    return isMobileView;
  };
  let isMobile = useMobileView();
  return (
    <>
      <Helmet>
        <title>RocketPages - Site Settings</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {/* Include Google Analytics */}
      <GoogleAnalytics />
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading || isCheckingSSL}
        >
          <CircularProgress color="inherit" />
          {isCheckingSSL && (
            <p className="mt-4">Updating your site's domain...</p>
          )}
        </Backdrop>
        <Navbar
          {...stateData}
          isPublished={formData.isPublished}
          siteId={id}
          title={`Site settings${!isMobile ? ` : ${formData ? formData.siteName : ""}` : ""
            }`}
          siteUrl={
            formData.siteUrl ? `https://${formData.siteUrl}` : ""
          }
        />
        <div className="bg-gray-100 flex-row fixed h-screen w-48 top-[64px] hidden md:flex">
          {/* <div className="flex "> */}
          <div className="left-navbar-tabs w-full h-full bg-catelina-blue text-white flex flex-col py-2">
            {siteCategory.map((ii) => {
              return (
                <span
                  className={`px-6 hover:cursor-pointer py-2 left-sidebar-tab-${ii
                    .toLowerCase()
                    .replace(/\s+/g, "-")} ${
                    selectedCategory === ii
                      ? "bg-white-smoke text-catelina-blue"
                      : ""
                  }`}
                  key={ii}
                  onClick={() => setSelectedCategory(ii)}
                >
                  {ii}
                </span>
              );
            })}
          </div>
          {/* </div> */}
        </div>
        <div className=" left-navbar-tab-contents min-h-screen flex w-full my-4 px-4 md:pl-[280px] pb-10 pt-[64px] bg-white-smoke flex-col">
          <div className="flex md:hidden py-2">
            <CategoryDropdown
              items={siteCategory}
              handleChange={setSelectedCategory}
              category={selectedCategory}
            />
          </div>
          {formData && selectedCategory === "General" && (
            <ConfigGeneral
              siteId={formData.siteId}
              onDataChange={onFormDataChange}
              faviconImage={formData.faviconImage}
              siteName={formData.siteName}
              defaultSocialMediaImage={formData.defaultSocialMediaImage}
              defaultSocialMediaDescription={formData.defaultSocialMediaDescription}
              defaultSocialMediaImageAlt={formData.defaultSocialMediaImageAlt}
            />
          )}
          {formData &&
            selectedCategory === "Administration" &&
            cookies.rp_user_data.userType === "admin" && (
              <ConfigAdministration
                siteId={formData.siteId}
                onDataChange={onFormDataChange}
                data={formData}
                allTemplateColors={allTemplateColors}
                templateSiteCategories={templateSiteCategories}
                // faviconImage={formData.faviconImage}
                // siteName={formData.siteName}
                // defaultSocialMediaImage={formData.defaultSocialMediaImage}
              />
            )}
          {formData && selectedCategory === "Domain" && (
            <ConfigDomain
              siteId={formData.siteId}
              userData={cookies.rp_user_data}
              onDataChange={onFormDataChange}
              subDomain={formData.subDomain}
              subDomainErrorMessage={formData.subDomainErrorMessage}
              selectedDomainTab={selectedDomainTab}
              onTabChange={(tab) => {
                setSelectedDomainTab(tab);
              }}
              isPublished={formData.isPublished}
              setIsLoading={setIsLoading}
              setIsCheckingSSL={setIsCheckingSSL}
              checkPublishStatus={checkPublishStatus}
            />
          )}
          {formData && selectedCategory === "Custom Code" && (
            <ConfigCustomCode
              onDataChange={onFormDataChange}
              head_customHtmlCode={formData.head_customHtmlCode}
              body_customHtmlCode={formData.body_customHtmlCode}
            />
          )}
          {formData && selectedCategory === "SEO" && (
            <ConfigSEO
              onDataChange={onFormDataChange}
              primaryLanguage={formData.primaryLanguage}
              redirects={formData.redirects ?? []}
              setSaveDisabled={setSaveDisabled}
            />
          )}
          {formData && selectedCategory === "Widgets" && (
            <ConfigWidgets
              onDataChange={onFormDataChange}
              cookies={formData.cookies}
            />
          )}
          {formData &&
            selectedCategory === "Contributors" &&
            formData.siteOwnerUserId === cookies.rp_user_data.userId && (
              <ConfigContributors
                onDataChange={onFormDataChange}
                addOnUsers={formData.addOnUsers}
                onAddOnUsersAdd={onAddOnUsersAdd}
                onAddOnUsersRemove={onAddOnUsersRemove}
              />
            )}
          {formData && selectedCategory === "Contact Form" && (
            <ConfigContactForm siteId={id} />
          )}
          {formData && formData.siteOwnerUserId === cookies.rp_user_data.userId && (
            <>
              {selectedCategory === "Plans" && <ConfigPricingPlan />}
              {selectedCategory === "Billing" && <ConfigBillings />}
              {selectedCategory === "Subscriptions" && <ConfigSubscriptions />}
            </>
          )}
        </div>
        {selectedCategory !== "Contributors" &&
          selectedCategory !== "Contact Form" &&
          (selectedCategory !== "Domain" ||
            selectedDomainTab !== "customdomain") &&
          selectedCategory !== "Billing" &&
          selectedCategory !== "Plans" &&
          selectedCategory !== "Subscriptions" && (
            <div className="fixed bottom-0 w-full z-50 flex justify-end px-4 py-2 bg-white-smoke">
              <Button
                id="save"
                disabled={saveDisabled}
                className="min-w-[6rem] px-4 py-1 capitalize"
                sx={{
                  textTransform: "none",
                }}
                size="small"
                variant="contained"
                onClick={onSave}
              >
                Save {siteData.isPublished && " & Publish"}
              </Button>
            </div>
          )}
      </div>
      <CookieConsent />
    </>
  );
};
